import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import {get, isNil, set, toInteger, toLower} from 'lodash';
import moment from 'moment';
import {toastr} from 'helper/toastrIntercept';
import {SketchPicker} from 'react-color';
import { settingConstants } from '../../../../constants/SettingConstants';
import Select from '../../../selectbox/Select';
import TextInput from '../../../input/TextInput';
import RadioGroup from '../../../radio/RadioGroup';
import {commonOptions, deviceOptions} from '../../../misopt';
import NumberInput from '../../../input/NumberInput';
import Checkbox from '../../../checkbox/Checkbox';
import {isAllNil} from '../../../../helper';
import WhiteButton from '../../../button/WhiteButton';
import TimeSelect from '../../../selectbox/TimeSelect';
import {APLAYER, IPLAYER, LEDBOX, LPLAYER, RIPLAYER, RLEDBOX, RSIGNAGE, SIGNAGE} from '../../../../constants';

const defaultSetupInfo = {
    data: {},
    changed: {},
    errors: {},
};

const timeFormat = 'HH:mm';

export const EditTimeZone = ({ preset, setupInfo, onChange, onChangeChecked }) => {
    const {t} = useTranslation();
    const {data: {deviceType, timeZoneIndex, timeZoneList = [], dayLightSaving, dayLightSavingManual, dstStartMonth, dstStartWeek, dstStartDay, dstStartTime, dstEndMonth, dstEndWeek, 
        dstEndDay, dstEndTime, dstTimeDifference} = {}, changed, errors, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(!preset && isAllNil(timeZoneIndex, dayLightSaving)) {
        return null;
    }

    const tzs = preset ? deviceOptions.timeZone.slice().reverse() : timeZoneList.map(tz => ({value: tz.index, title: tz.index})).reverse();
    
    const onChangeStartTime = value => {
        if(value) {
            onChange({'data-name': 'dstStartTime'}, value.format(timeFormat));
        }
    };

    const onChangeEndTime = value => {
        if(value) {
            onChange({'data-name': 'dstEndTime'}, value.format(timeFormat));
        }
    };

    return (
        deviceType !== APLAYER &&
        <div className="devicepop setup mb26">
            <h3>{t('COM_TABLE_TIME_ZONE_INDEX_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            (preset || (timeZoneIndex && deviceType !== APLAYER)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='timeZoneIndexEnable' name={t('COM_TABLE_TIME_ZONE_INDEX_P')} checked={changed['timeZoneIndex'] || false}
                                            propertyName='timeZoneIndex' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['timeZoneIndex'], 
                                        'response_warning': !isNil(get(responseWarning, 'timeZoneIndex'))
                                        })}>
                                        {t('COM_TABLE_TIME_ZONE_INDEX_P')}
                                    </th>
                                }
                                <td colSpan="2">
                                    <Select propertyName='timeZoneIndex' value={timeZoneIndex} selects={tzs} onChange={onChange} multiLang={false} 
                                        disabled={preset && !changed['timeZoneIndex']} />
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(dayLightSaving)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='dayLightSavingEnable' name={t('TABLE_DAY_LIGHT_SAVING_P')} checked={changed['dayLightSaving'] || false}
                                            propertyName='dayLightSaving' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['dayLightSaving'],
                                        'response_warning': !isNil(get(responseWarning, 'dayLightSaving'))
                                        })}>
                                        {t('TABLE_DAY_LIGHT_SAVING_P')}
                                    </th>
                                }
                                <td colSpan="2">
                                    <RadioGroup propertyName='dayLightSaving' selects={deviceOptions.dayLightSaving} value={dayLightSaving} 
                                        onChange={onChange} disabled={preset && !changed['dayLightSaving']} />
                                </td>
                            </tr>
                        }
                        {
                            (preset ? changed['dayLightSaving'] && dayLightSaving : dayLightSaving && !isNil(dayLightSavingManual) && deviceType !== IPLAYER && deviceType !== RIPLAYER && deviceType !== LPLAYER) &&
                            <>
                                <tr>
                                    <th className={classNames({
                                        'changed': !preset && (changed['dstStartMonth'] || changed['dstStartWeek'] || changed['dstStartDay'] || changed['dstStartTime'])
                                    })}>
                                        {t('COM_TABLE_START_DATE_P')}
                                    </th>
                                    <td className={classNames({'changed': !preset && changed['dstStartMonth']})}>{t('TEXT_MONTH2_P')}</td>
                                    <td>
                                        <Select width={100} propertyName='dstStartMonth' selects={commonOptions.month} value={dstStartMonth} onChange={onChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({'changed': !preset && changed['dstStartWeek']})}>{t('MIS_TEXT_STATISTICS_POP_WEEK_P')}</td>
                                    <td><Select width={100} propertyName='dstStartWeek' selects={commonOptions.week} value={dstStartWeek} onChange={onChange} /></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({'changed': !preset && changed['dstStartDay']})}>{t('TEXT_WEEKDAY_P')}</td>
                                    <td><Select width={100} propertyName='dstStartDay' selects={commonOptions.day} value={dstStartDay} onChange={onChange} /></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({'changed': !preset && changed['dstStartTime']})}>{t('TEXT_TIME_P')}</td>
                                    <td>
                                        <TimeSelect id='dstStartTime' width={100} value={moment(dstStartTime, timeFormat)} format={timeFormat} showSecond={false} onChange={onChangeStartTime} />
                                        {/* <TimePicker style={{width: 100}} id='dstStartTime' value={moment(dstStartTime, timeFormat)} format={timeFormat} showSecond={false} onChange={onChangeStartTime} /> */}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classNames({
                                        'changed': !preset && (changed['dstEndMonth'] || changed['dstEndWeek'] || changed['dstEndDay'] || changed['dstEndTime'])
                                        })}>{t('COM_TABLE_END_DATE_P')}</th>
                                    <td className={classNames({'changed': !preset && changed['dstEndMonth']})}>{t('TEXT_MONTH2_P')}</td>
                                    <td>
                                        <Select width={100} propertyName='dstEndMonth' selects={commonOptions.month} value={dstEndMonth} onChange={onChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({'changed': !preset && changed['dstEndWeek']})}>{t('MIS_TEXT_STATISTICS_POP_WEEK_P')}</td>
                                    <td><Select width={100} propertyName='dstEndWeek' selects={commonOptions.week} value={dstEndWeek} onChange={onChange} /></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({'changed': !preset && changed['dstEndDay']})}>{t('TEXT_WEEKDAY_P')}</td>
                                    <td><Select width={100} propertyName='dstEndDay' selects={commonOptions.day} value={dstEndDay} onChange={onChange} /></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({'changed': !preset && changed['dstEndTime']})}>{t('TEXT_TIME_P')}</td>
                                    <td>
                                        <TimeSelect id='dstEndTime' width={100} value={moment(dstEndTime, timeFormat)} format={timeFormat} showSecond={false} onChange={onChangeEndTime} />
                                        {/* <TimePicker style={{width: 100}} id='dstEndTime' value={moment(dstEndTime, timeFormat)} format={timeFormat} showSecond={false} onChange={onChangeEndTime} /> */}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classNames({'changed': !preset && changed['dstTimeDifference']})}>{t('MIS_TEXT_TIME_DIFFERENCE_P')}</th>
                                    <td colSpan="2"><Select width={100} propertyName='dstTimeDifference' selects={commonOptions.timeDiff} value={dstTimeDifference} onChange={onChange} /></td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

EditTimeZone.defaultProps = {
    setupInfo: {},
};

export const EditNetwork = ({preset, isVwt, setupInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {deviceType, magicinfoServerUrl, proxySetting, proxyException, ftpConnectMode, connectionLimitTime, bandwidth, protocolPriority, thirdApplicationUpdateDomain} = {}, 
        changed, errors, responseWarning = {}} = setupInfo || defaultSetupInfo;
    const [proxyChanged, setProxyChanged] = useState([false, false, false, false, false]);

    if(!preset && isAllNil(magicinfoServerUrl, proxySetting, proxyException, ftpConnectMode, connectionLimitTime, bandwidth, protocolPriority, thirdApplicationUpdateDomain)) {
        return null;
    }

    const [proxyUse, proxyAddress, proxyPort, proxyUserId, proxyPassword] = (proxySetting || '').split(';');

    const onChangeProxyUse = (e, value) => {
        onChange(e, `${value};${proxyAddress};${proxyPort};${proxyUserId};${proxyPassword}`);
        const newProxyChanged = [...proxyChanged];
        newProxyChanged[0] = true;
        setProxyChanged(newProxyChanged);
    };

    const onChangeProxyAddress = e => {
        onChange(e, `${proxyUse};${e.target.value};${proxyPort};${proxyUserId};${proxyPassword}`);
        const newProxyChanged = [...proxyChanged];
        newProxyChanged[1] = true;
        setProxyChanged(newProxyChanged);
    };

    const onChangeProxyPort = e => {
        onChange(e, `${proxyUse};${proxyAddress};${e.target.value};${proxyUserId};${proxyPassword}`);
        const newProxyChanged = [...proxyChanged];
        newProxyChanged[2] = true;
        setProxyChanged(newProxyChanged);
    };

    const onChangeProxyUserId = e => {
        onChange(e, `${proxyUse};${proxyAddress};${proxyPort};${e.target.value};${proxyPassword}`);
        const newProxyChanged = [...proxyChanged];
        newProxyChanged[3] = true;
        setProxyChanged(newProxyChanged);
    };

    const onChangeProxyPassword = e => {
        onChange(e, `${proxyUse};${proxyAddress};${proxyPort};${proxyUserId};${e.target.value}`);
        const newProxyChanged = [...proxyChanged];
        newProxyChanged[4] = true;
        setProxyChanged(newProxyChanged);
    };

    const onKeyDownPassword = e => {
        if(e.keyCode === 186)
            e.preventDefault();
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_TV_SID_SERVER_NETWORK_SETTING')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(magicinfoServerUrl) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['magicinfoServerUrl'],
                                    'response_warning': !isNil(get(responseWarning, 'magicinfoServerUrl'))
                                    })}>{t('TABLE_MAGICINFO_SERVER_URL_P')}</th>
                                <td colSpan="2">
                                    <TextInput width={250} propertyName='magicinfoServerUrl' value={magicinfoServerUrl} onChange={onChange} disabled={isVwt} />
                                    {
                                        isVwt &&
                                        <span>{t('MIS_SID_NOT_EDIT_SERVER_URLS_VIDEO_WALL')}</span>
                                    }
                                </td>
                            </tr>
                        }
                        {
                            proxySetting &&
                            <>
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['proxySetting'],
                                        'response_warning': !isNil(get(responseWarning, 'proxySetting'))
                                        })}>{t('COM_TEXT_PROXY_SETTING_P')}</th>
                                    <td colSpan="2">
                                        <RadioGroup propertyName='proxySetting' selects={deviceOptions.proxyUsage} value={proxyUse} onChange={onChangeProxyUse} />
                                    </td>
                                </tr>
                                {
                                    proxyUse == deviceOptions.proxyUsage[1].value &&
                                    <>
                                        <tr>
                                            <th></th>
                                            <th className={classNames({'changed': proxyChanged[1]})}>{t('COM_MIS_TEXT_ADDRESS_P')}</th>
                                            <td colSpan="2">
                                                <TextInput width={250} propertyName='proxySetting' value={proxyAddress} onChange={onChangeProxyAddress} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th className={classNames({'changed': proxyChanged[2]})}>{t('TABLE_PORT_P')}</th>
                                            <td colSpan="2">
                                                <TextInput width={250} propertyName='proxySetting' value={proxyPort} onChange={onChangeProxyPort} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th className={classNames({'changed': proxyChanged[3]})}>{t('COM_DID_ADMIN_USER_USERID')}</th>
                                            <td colSpan="2">
                                                <TextInput width={250} propertyName='proxySetting' value={proxyUserId} onChange={onChangeProxyUserId} maxLength={20}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th className={classNames({'changed': proxyChanged[4]})}>{t('TEXT_PASSWORD_P')}</th>
                                            <td colSpan="2">
                                                <TextInput width={250} type='password' propertyName='proxySetting' value={proxyPassword} onChange={onChangeProxyPassword} onKeyDown={onKeyDownPassword} maxLength={20}/>
                                            </td>
                                        </tr>
                                        {
                                            proxyException !== undefined &&
                                            <>
                                                <tr>
                                                    <th></th>
                                                    <th className={classNames({'changed': changed['proxyException']})}>{t('MIS_SID_PREMIUM_PROXY_EXCEPTION')}</th>
                                                    <td colSpan="2">
                                                        <TextInput width={250} propertyName='proxyException' maxLength={1000} value={proxyException} onChange={onChange} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th></th>
                                                    <th></th>
                                                    <td colSpan="2">*{t('MIS_SID_PREMIUM_USE_SEMICOLONS_SEPARATE')}</td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                }
                                
                            </>
                        }
                        {
                            !isNil(thirdApplicationUpdateDomain) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['thirdApplicationUpdateDomain'],
                                    'response_warning': !isNil(get(responseWarning, 'thirdApplicationUpdateDomain'))
                                    })}>{t('MIS_SID_SERVER_CBMAR_KIOSK_APP_UPDATE_DOMAIN')}</th>
                                <td colSpan="2">
                                    <TextInput width={250} propertyName='thirdApplicationUpdateDomain' value={thirdApplicationUpdateDomain} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(ftpConnectMode)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='ftpConnectModeEnable' name={t('TABLE_FTP_CONNECT_MODE_P')} checked={changed['ftpConnectMode'] || false}
                                            propertyName='ftpConnectMode' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['ftpConnectMode'],
                                        'response_warning': !isNil(get(responseWarning, 'ftpConnectMode'))
                                    })}>{t('TABLE_FTP_CONNECT_MODE_P')}</th>
                                }
                                <td colSpan="2">
                                    <RadioGroup propertyName='ftpConnectMode' selects={deviceOptions.ftpOptions} value={ftpConnectMode} 
                                        onChange={onChange} disabled={preset && !changed['ftpConnectMode']} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(connectionLimitTime) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['connectionLimitTime'],
                                    'response_warning': !isNil(get(responseWarning, 'connectionLimitTime'))
                                    })}>{t('TEXT_CONNECTION_LIMIT_TIME_P')}</th>
                                <td colSpan="2">
                                    <NumberInput propertyName='connectionLimitTime' width={70} value={connectionLimitTime} onChange={onChange} min={1} max={300} forceInt />
                                    <span className="space float_l">{t('COM_SID_SEC')}</span>
                                </td>
                            </tr>
                        }
                        {
                            (preset || (!isNil(bandwidth) && deviceType !== APLAYER)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='bandwidthEnable' name={t('MIS_SID_NETWROK_BANDWIDTH_LIMIT')} checked={changed['bandwidth'] || false}
                                            propertyName='bandwidth' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['bandwidth'],
                                        'response_warning': !isNil(get(responseWarning, 'bandwidth'))
                                    })}>{t('MIS_SID_NETWROK_BANDWIDTH_LIMIT')}</th>
                                }
                                <td colSpan="2">
                                    <NumberInput propertyName='bandwidth' width={85} value={bandwidth} onChange={onChange} min={0} max={10240} step={100}
                                        disabled={preset && !changed['bandwidth']} forceInt />
                                    <span className="space float_l">{t('MIS_SID_20_KBPS')}</span>
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(protocolPriority)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='protocolPriorityEnable' name={t('MIS_SID_CONTENT_DOWNLOAD_PROTOCOL')} checked={changed['protocolPriority'] || false}
                                            propertyName='protocolPriority' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['protocolPriority'],
                                        'response_warning': !isNil(get(responseWarning, 'protocolPriority'))
                                    })}>{t('MIS_SID_CONTENT_DOWNLOAD_PROTOCOL')}</th>
                                }
                                <td colSpan="2">
                                    <RadioGroup propertyName='protocolPriority' value={protocolPriority} selects={deviceOptions.contentDownloadProtocol}
                                        onChange={onChange} disabled={preset && !changed['protocolPriority']} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const EditSmartFeature = ({preset, setupInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {smartDownload} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(!preset && isAllNil(smartDownload)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('MIS_SID_SMART_FEATURE')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    {
                        (preset || !isNil(smartDownload)) &&
                        <tr>
                            {
                                preset ?
                                <th>
                                    <Checkbox id='smartDownloadEnable' name={t('MIS_SID_SMART_DOWNLOAD')} checked={changed['smartDownload'] || false}
                                        propertyName='smartDownload' onChange={onChangeChecked} />
                                </th> :
                                <th className={classNames({
                                    'changed': changed['smartDownload'],
                                    'response_warning': !isNil(get(responseWarning, 'smartDownload'))
                                    })}>
                                    {t('MIS_SID_SMART_DOWNLOAD')}
                                </th>
                            }
                            <td colSpan="2">
                                <RadioGroup propertyName='smartDownload' selects={deviceOptions.onOffOptions} value={smartDownload} onChange={onChange} 
                                    disabled={preset ? !changed['smartDownload'] : smartDownload == -1} />
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const defaultRestartTime = moment('00:00', timeFormat);

export const EditPeriod = ({preset, setupInfo, onChange, onChangeChecked}) => {
    const {t} = useTranslation();
    const {data: {deviceType, triggerInterval, monitoringInterval, screenCaptureInterval, switchTime, systemRestartInterval, childMonitoringInterval} = {}, 
        changed, errors, responseWarning = {}} = setupInfo || defaultSetupInfo;
    const [switchTimeChanged, setSwitchTimeChanged] = useState([false, false]);

    if(!preset && isAllNil(triggerInterval, monitoringInterval, screenCaptureInterval)) {
        return null;
    }

    const [switchPdf, switchPpt] = (switchTime || '').split(';').map(e => toInteger(e));
    const [systemRestartDay, systemRestartTime] = (systemRestartInterval || '').split(' ');
    const systemRestartDays = (systemRestartDay || '').trim().split(';').filter(e => e.length > 0).map(e => toLower(e));

    const onChangeSwitchPdf = (e, value) => {
        const newChanged = [...switchTimeChanged];
        newChanged[0] = true;
        setSwitchTimeChanged(newChanged);
        onChange(e, `${value};${switchPpt}`);
    };

    const onChangeSwitchPpt = (e, value) => {
        const newChanged = [...switchTimeChanged];
        newChanged[1] = true;
        setSwitchTimeChanged(newChanged);
        onChange(e, `${switchPdf};${value}`);
    };

    const onChangeSystemRestartDay = (e, value) => {
        if(e.target.checked) {
            onChange(e, `${[...systemRestartDays, value].join(';')} ${systemRestartTime}`);
        } else {
            const newDays = systemRestartDays.filter(day => day !== value);
            // newDays.splice(newDays.findIndex(e => e === value), 1);
            onChange(e, `${newDays.join(';')} ${systemRestartTime}`);
        }
    };

    const onChangeSystemRestartTime = e => {
        if(e) {
            onChange({'data-name': 'systemRestartInterval'}, `${systemRestartDay} ${e.format(timeFormat)}`);
        }
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_TEXT_PERIOD2_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            (preset || !isNil(triggerInterval)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='triggerIntervalEnable' name={t('TABLE_TRIGGER_INTERVAL_P')} checked={changed['triggerInterval'] || false}
                                            propertyName='triggerInterval' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['triggerInterval'],
                                        'response_warning': !isNil(get(responseWarning, 'triggerInterval'))
                                        })}>
                                        {t('TABLE_TRIGGER_INTERVAL_P')}
                                    </th>
                                }
                                <td colSpan="2">
                                    <NumberInput width={70} propertyName='triggerInterval' value={triggerInterval} onChange={onChange} 
                                        disabled={preset && !changed['triggerInterval']} min={1} max={60} forceInt />
                                    <span className="space float_l">{t('COM_SID_SEC')}</span>
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(monitoringInterval)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='monitoringIntervalEnable' name={t('TABLE_MONITORING_INTERVAL_P')} checked={changed['monitoringInterval'] || false}
                                            propertyName='monitoringInterval' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['monitoringInterval'],
                                        'response_warning': !isNil(get(responseWarning, 'monitoringInterval'))
                                    })}>{t('TABLE_MONITORING_INTERVAL_P')}</th>
                                }
                                <td colSpan="2">
                                    <NumberInput width={70} propertyName='monitoringInterval' value={monitoringInterval} onChange={onChange} 
                                        disabled={preset && !changed['monitoringInterval']} min={1} max={60} forceInt />
                                    <span className="space float_l">{t('COM_SID_MIN')}</span>
                                </td>
                            </tr>
                        }
                        {
                            !preset && !isNil(childMonitoringInterval) && (deviceType === SIGNAGE || deviceType === RSIGNAGE || deviceType === LEDBOX || deviceType === RLEDBOX) &&
                            <tr>
                                <th className={classNames({'changed': changed['childMonitoringInterval']})}>{t('MIS_SID_SERVER_CASEP_SECONDARY_DEVICE_MONITORING_CYCLE')}</th>
                                <td colSpan="2">
                                    {
                                        (deviceType === SIGNAGE || deviceType === RSIGNAGE) &&
                                        <NumberInput propertyName='childMonitoringInterval' value={childMonitoringInterval} onChange={onChange} min={1} max={60} forceInt />
                                    }
                                    {
                                        (deviceType === LEDBOX || deviceType === RLEDBOX) &&
                                        <NumberInput propertyName='childMonitoringInterval' value={childMonitoringInterval} onChange={onChange} min={30} max={60} forceInt />
                                    }
                                    <span className="space float_l">{t('COM_SID_MIN')}</span>
                                </td>
                            </tr>
                        }
                        {
                            (preset || !isNil(screenCaptureInterval)) &&
                            <tr>
                                {
                                    preset ?
                                    <th>
                                        <Checkbox id='screenCaptureIntervalEnable' name={t('TABLE_SCREEN_CAPTURE_INTERVAL_P')} checked={changed['screenCaptureInterval'] || false}
                                            propertyName='screenCaptureInterval' onChange={onChangeChecked} />
                                    </th> :
                                    <th className={classNames({
                                        'changed': changed['screenCaptureInterval'],
                                        'response_warning': !isNil(get(responseWarning, 'screenCaptureInterval'))
                                    })}>{t('TABLE_SCREEN_CAPTURE_INTERVAL_P')}</th>
                                }
                                <td colSpan="2">
                                    <NumberInput width={70} propertyName='screenCaptureInterval' value={screenCaptureInterval} onChange={onChange} 
                                        disabled={preset && !changed['screenCaptureInterval']} min={1} max={180} forceInt />
                                    <span className="space float_l">{t('COM_SID_MIN')}</span>
                                </td>
                            </tr>
                        }
                        {
                            !isNil(switchTime) &&
                            <>
                                <tr>
                                    <th className={classNames({'changed': changed['switchTime'] && switchTimeChanged[0]})}>{t('MIS_SID_20_PDF_CONVERSION_INTERVAL')}</th>
                                    <td colSpan="2">
                                        <NumberInput width={70} propertyName='switchTime' value={switchPdf} onChange={onChangeSwitchPdf} min={1} max={3599} forceInt />
                                        <span className="space float_l">{t('COM_SID_SEC')}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th className={classNames({'changed': changed['switchTime'] && switchTimeChanged[1]})}>{t('MIS_SID_20_OFFICE_CONVERSION_INTERVAL')}</th>
                                    <td colSpan="2">
                                        <NumberInput width={70} propertyName='switchTime' value={switchPpt} onChange={onChangeSwitchPpt} min={1} max={3599} forceInt />
                                        <span className="space float_l">{t('COM_SID_SEC')}</span>
                                    </td>
                                </tr>
                            </>
                        }
                        {
                            (preset || systemRestartInterval) &&
                            <>
                                <tr>
                                    {
                                        preset ?
                                        <th>
                                            <Checkbox id='systemRestartIntervalEnable' name={t('TEXT_SYSTEM_RESTART_INTERVAL_P')} checked={changed['systemRestartInterval'] || false}
                                                propertyName='systemRestartInterval' onChange={onChangeChecked} />
                                        </th> :
                                        <th className={classNames({
                                            'changed': changed['systemRestartInterval'],
                                            'response_warning': !isNil(get(responseWarning, 'systemRestartInterval'))
                                            })}>
                                            {t('TEXT_SYSTEM_RESTART_INTERVAL_P')}
                                        </th>
                                    }
                                    <td colSpan="2" className="system_restart_interval">
                                        <Checkbox 
                                            id='COM_TEXT_DAY_SUNDAY_P' 
                                            name={t('COM_TEXT_DAY_SUNDAY_P')} 
                                            propertyName='systemRestartInterval' 
                                            checked={systemRestartDays.includes('sun')} 
                                            disabled={preset && !changed['systemRestartInterval']}
                                            onChange={e => onChangeSystemRestartDay(e, 'sun')} />
                                        <Checkbox 
                                            id='COM_TEXT_DAY_MONDAY_P' 
                                            name={t('COM_TEXT_DAY_MONDAY_P')} 
                                            propertyName='systemRestartInterval' 
                                            checked={systemRestartDays.includes('mon')} 
                                            disabled={preset && !changed['systemRestartInterval']}
                                            onChange={e => onChangeSystemRestartDay(e, 'mon')} />
                                        <Checkbox 
                                            id='COM_TEXT_DAY_TUESDAY_P' 
                                            name={t('COM_TEXT_DAY_TUESDAY_P')} 
                                            propertyName='systemRestartInterval' 
                                            checked={systemRestartDays.includes('tue')} 
                                            disabled={preset && !changed['systemRestartInterval']}
                                            onChange={e => onChangeSystemRestartDay(e, 'tue')} />
                                        <Checkbox 
                                            id='COM_TEXT_DAY_WEDNESDAY_P' 
                                            name={t('COM_TEXT_DAY_WEDNESDAY_P')} 
                                            propertyName='systemRestartInterval' 
                                            checked={systemRestartDays.includes('wed')} 
                                            disabled={preset && !changed['systemRestartInterval']}
                                            onChange={e => onChangeSystemRestartDay(e, 'wed')} />
                                        <Checkbox 
                                            id='COM_TEXT_DAY_THURSDAY_P' 
                                            name={t('COM_TEXT_DAY_THURSDAY_P')} 
                                            propertyName='systemRestartInterval' 
                                            checked={systemRestartDays.includes('thu')} 
                                            disabled={preset && !changed['systemRestartInterval']}
                                            onChange={e => onChangeSystemRestartDay(e, 'thu')} />
                                        <Checkbox 
                                            id='COM_TEXT_DAY_FRIDAY_P' 
                                            name={t('COM_TEXT_DAY_FRIDAY_P')} 
                                            propertyName='systemRestartInterval' 
                                            checked={systemRestartDays.includes('fri')} 
                                            disabled={preset && !changed['systemRestartInterval']}
                                            onChange={e => onChangeSystemRestartDay(e, 'fri')} />
                                        <Checkbox 
                                            id='COM_TEXT_DAY_SATURDAY_P' 
                                            name={t('COM_TEXT_DAY_SATURDAY_P')} 
                                            propertyName='systemRestartInterval' 
                                            checked={systemRestartDays.includes('sat')} 
                                            disabled={preset && !changed['systemRestartInterval']}
                                            onChange={e => onChangeSystemRestartDay(e, 'sat')} />
                                    </td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td colSpan="2">
                                        <TimeSelect id='systemRestartInterval' width={100} value={moment(systemRestartTime, timeFormat)} format={timeFormat} 
                                            showSecond={false} onChange={onChangeSystemRestartTime} disabled={(preset && !changed['systemRestartInterval']) || systemRestartDays.length === 0} />
                                        {/* <TimePicker style={{width: 100}} id='systemRestartInterval' value={moment(systemRestartTime, timeFormat)} format={timeFormat} 
                                            showSecond={false} onChange={onChangeSystemRestartTime} disabled={preset && !changed['systemRestartInterval']} /> */}
                                    </td>
                                </tr>
                            </>
                        }

                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditEtc = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {screenRotation, rmDataSetting, computerName, useMpplayer, resetPassword, bgColor, playerResolution} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;
    const [widthChanged, setWidthChanged] = useState(false);
    const [heightChanged, setHeightChanged] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);

    if(isAllNil(screenRotation, rmDataSetting, computerName, useMpplayer, resetPassword, bgColor, playerResolution)) {
        return null;
    }

    const onChangeResetPassword = e => {
        if(e.target.checked) {
            onChange(e, 1);
        } else {
            onChange(e, 0);
        }
    };

    const resolution = (playerResolution || '').split('*').map(e => toInteger(e));
    
    const onChangeWidth = (e, value) => {
        if(resolution && resolution.length === 2) {
            onChange(e, `${value}*${resolution[1]}`);
            setWidthChanged(true);
        }
    };

    const onChangeHeight = (e, value) => {
        if(resolution && resolution.length === 2) {
            onChange(e, `${resolution[0]}*${value}`);
            setHeightChanged(true);
        }
    };

    const onChangeBgColor = color => {
        onChange({'data-name': 'bgColor'}, color.hex.substr(1));
        setShowColorPicker(false);
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('DID_LAYOUTS_COMMON_ETC')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(screenRotation) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['screenRotation'],
                                    'response_warning': !isNil(get(responseWarning, 'screenRotation'))
                                    })}>{t('TEXT_SCREEN_ROTATION_P')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='screenRotation' selects={deviceOptions.screenRotation} value={screenRotation} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(rmDataSetting) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['rmDataSetting'],
                                    'response_warning': !isNil(get(responseWarning, 'rmDataSetting'))
                                    })}>{t('MIS_SID_DEVICE_STATUS_DATA_SETTINGS')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='rmDataSetting' selects={deviceOptions.rmDataSetting} value={rmDataSetting} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(computerName) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['computerName'],
                                    'response_warning': !isNil(get(responseWarning, 'computerName'))
                                    })}>{t('MIS_TEXT_COMPUTERNAME_P')}</th>
                                <td colSpan="2">
                                    <TextInput propertyName='computerName' value={computerName} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(useMpplayer) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['useMpplayer'],
                                    'response_warning': !isNil(get(responseWarning, 'useMpplayer'))
                                    })}>{t('MIS_TEXT_USE_MPPLAYER_P')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='useMpplayer' value={useMpplayer} selects={deviceOptions.tfOptions} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(resetPassword) &&
                            <tr>
                                <th className={classNames({'changed': changed['resetPassword']})}>{t('TEXT_RESET_PASSWORD_P')}</th>
                                <td colSpan="2">
                                    <Checkbox id='resetPassword' name={t('BUTTON_APPLY')} propertyName='resetPassword' checked={resetPassword == 1} onChange={onChangeResetPassword} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(bgColor) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['bgColor'],
                                    'response_warning': !isNil(get(responseWarning, 'bgColor'))
                                    })}>{t('COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR')}</th>
                                <td colSpan="2">
                                    <span className='color_button_wrap' onClick={() => setShowColorPicker(!showColorPicker)}>
                                        <span style={{display: 'inline-block', backgroundColor: `#${bgColor}`, width: 25, height: 20}}>&nbsp;</span>
                                        <span className='ml6'>▼</span>
                                    </span>
                                    {
                                        showColorPicker &&
                                        <div style={{position: 'absolute', zIndex: 99}}>
                                            <SketchPicker color={bgColor} onChangeComplete={onChangeBgColor} />
                                        </div>
                                    }
                                </td>
                            </tr>
                        }
                        {
                            resolution && resolution.length === 2 &&
                            <>
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['playerResolution'],
                                        'response_warning': !isNil(get(responseWarning, 'playerResolution'))
                                        })}>{t('MIS_SID_PLAYER_RESOLUTION')}</th>
                                    <td className={classNames({'changed': widthChanged})}>{t('COM_IDS_DS_PREVIEW_WIDTH')}</td>
                                    <td><NumberInput propertyName='playerResolution' value={resolution[0]} onChange={onChangeWidth} min={1} max={1920} forceInt /></td>
                                </tr>
                                <tr>
                                    <th></th>
                                    <td className={classNames({'changed': heightChanged})}>{t('COM_IDS_HEIGHT')}</td>
                                    <td><NumberInput propertyName='playerResolution' value={resolution[1]} onChange={onChangeHeight} min={1} max={1920} forceInt /></td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

EditEtc.defaultProps = {
    onChange: () => {}
};

const EditVNC = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {tunnelingServer, vncPassword} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(isAllNil(tunnelingServer, vncPassword)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('BUTTON_VNC_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(tunnelingServer) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['tunnelingServer'],
                                    'response_warning': !isNil(get(responseWarning, 'tunnelingServer'))
                                    })}>{t('TABLE_TUNNELING_SERVER_P')}</th>
                                <td colSpan="2">
                                    <TextInput propertyName='tunnelingServer' value={tunnelingServer} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(vncPassword) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['vncPassword'],
                                    'response_warning': !isNil(get(responseWarning, 'vncPassword'))
                                    })}>{t('COM_MIS_TEXT_VNC_PASSWORD_P')}</th>
                                <td colSpan="2">
                                    <TextInput propertyName='vncPassword' value={vncPassword} onChange={onChange} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditManage = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {repositoryPath, mntFolderPath, contentMnt, logMnt, proofOfPlayMnt, statisticsFileRequestTime, statisticsFileRefresh} = {}, 
        changed, responseWarning = {}} = setupInfo || defaultSetupInfo;
    
    if(isAllNil(proofOfPlayMnt, statisticsFileRequestTime, statisticsFileRefresh)) {
        return null;
    }
    
    const [popValidPeriod, popValidSize] = (proofOfPlayMnt || '').split(';').map(e => toInteger(e));
    const [contentValidPeriod, contentValidSize] = (contentMnt || '').split(';').map(e => toInteger(e));
    const [logLevel, logValidPeriod, logValidSize] = (logMnt || '').split(';').map(e => toInteger(e));

    const onChangeContentValidPeriod = (e, value) => {
        onChange(e, `${value};${contentValidSize}`);
        onChange({'data-name': 'contentValidPeriod'}, value);
    };

    const onChangeContentValidSize = (e, value) => {
        onChange(e, `${contentValidPeriod};${value}`);
        onChange({'data-name': 'contentValidSize'}, value);
    };

    const onChangeLogLevel = (e, value) => {
        onChange(e, `${value};${logValidPeriod};${logValidSize}`);
    };

    const onChangeLogValidPeriod = (e, value) => {
        onChange(e, `${logLevel};${value};${logValidSize}`);
        onChange({'data-name': 'logValidPeriod'}, value);
    };

    const onChangeLogValidSize = (e, value) => {
        onChange(e, `${logLevel};${logValidPeriod};${value}`);
        onChange({'data-name': 'logValidSize'}, value);
    };

    const onChangePopValidPeriod = (e, value) => {
        onChange(e, `${value};${popValidSize}`);
        onChange({'data-name': 'popValidPeriod'}, value);
    };

    const onChangePopValidSize = (e, value) => {
        onChange(e, `${popValidPeriod};${value}`);
        onChange({'data-name': 'popValidSize'}, value);
    };

    const onChangePopFileRefresh = e => {
        onChange(e, e.target.checked ? '1' : null);
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('TEXT_MANAGE_P')}</h3>
            {
                !isAllNil(repositoryPath, mntFolderPath) &&
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            {
                                repositoryPath &&
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['repositoryPath'],
                                        'response_warning': !isNil(get(responseWarning, 'repositoryPath'))
                                        })}>{t('MIS_SID_20_STORAGE_PATH')}</th>
                                    <td colSpan="2">
                                        <TextInput propertyName='repositoryPath' value={repositoryPath} onChange={onChange} />
                                    </td>
                                </tr>
                            }
                            {
                                mntFolderPath &&
                                <tr>
                                    <th className={classNames({
                                        'changed': changed['mntFolderPath'],
                                        'response_warning': !isNil(get(responseWarning, 'mntFolderPath'))
                                        })}>{t('TEXT_MANAGEMENT_FOLDER_PATH_P')}</th>
                                    <td colSpan="2">
                                        <TextInput propertyName='mntFolderPath' value={mntFolderPath} onChange={onChange} />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
            {
                contentMnt &&
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className={classNames({
                                    'changed': changed['contentMnt'],
                                    'response_warning': !isNil(get(responseWarning, 'contentMnt'))
                                    })}>{t('TEXT_CONTENT_MANAGEMENT_P')}</th>
                                <td className={classNames({'changed': changed['contentValidPeriod']})}>{t('TEXT_VALIDITY_PERIOD_P')}</td>
                                <td>
                                    <NumberInput propertyName='contentMnt' value={contentValidPeriod} onChange={onChangeContentValidPeriod} min={1} max={365} />
                                    <span>{t('TEXT_DAY_P')}</span>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td className={classNames({'changed': changed['contentValidSize']})}>{t('TEXT_VALIDITY_SIZE_P')}</td>
                                <td>
                                    <NumberInput propertyName='contentMnt' value={contentValidSize} onChange={onChangeContentValidSize} min={1} max={300} />
                                    <span>MB</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            {
                logMnt &&
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th className={classNames({
                                    'changed': changed['logMnt'],
                                    'response_warning': !isNil(get(responseWarning, 'logMnt'))
                                    })}>{t('TEXT_LOG_LEVEL_P')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='logMnt' value={logLevel} selects={deviceOptions.logLevelOptions} onChange={onChangeLogLevel} />
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td className={classNames({'changed': changed['logValidPeriod']})}>{t('TEXT_VALIDITY_PERIOD_P')}</td>
                                <td>
                                    <NumberInput propertyName='logMnt' value={logValidPeriod} onChange={onChangeLogValidPeriod} min={1} max={365} />
                                    <span>{t('TEXT_DAY_P')}</span>
                                </td>
                            </tr>
                            <tr>
                                <th></th>
                                <td className={classNames({'changed': changed['logValidSize']})}>{t('TEXT_VALIDITY_SIZE_P')}</td>
                                <td>
                                    <NumberInput propertyName='logMnt' value={logValidSize} onChange={onChangeLogValidSize} min={1} max={300} />
                                    <span>MB</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            {
                !isAllNil(proofOfPlayMnt, statisticsFileRequestTime, statisticsFileRefresh) &&
                <div className="detail_view mini_p mt14 mb15">
                    <table>
                        <colgroup>
                            <col width="203px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            {
                                proofOfPlayMnt &&
                                <>
                                    <tr>
                                        <th className={classNames({
                                            'changed': changed['proofOfPlayMnt'],
                                            'response_warning': !isNil(get(responseWarning, 'proofOfPlayMnt'))
                                            })}>{t('TEXT_PROOF_OF_PLAY_MANAGEMENT_P')}</th>
                                        <td className={classNames({'changed': changed['popValidPeriod']})}>{t('TEXT_VALIDITY_PERIOD_P')}</td>
                                        <td>
                                            <NumberInput propertyName='proofOfPlayMnt' width={70} min={1} max={365} value={popValidPeriod} onChange={onChangePopValidPeriod} forceInt />
                                            <span className="space float_l">{t('TEXT_DAY_P')}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td className={classNames({'changed': changed['popValidSize']})}>{t('TEXT_VALIDITY_SIZE_P')}</td>
                                        <td>
                                            <NumberInput propertyName='proofOfPlayMnt' width={70} min={1} max={300} value={popValidSize} onChange={onChangePopValidSize} forceInt />
                                            <span className="space float_l">MB</span>
                                        </td>
                                    </tr>
                                </>
                            }
                            {
                                statisticsFileRequestTime &&
                                <tr>
                                    <th></th>
                                    <td>{t('MIS_TEXT_LAST_CHECK_TIME_P')}</td>
                                    <td>
                                        {statisticsFileRequestTime}
                                        {/* <Moment format={dataFormat}>{lastConnectionTime}</Moment> */}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <th></th>
                                <td className={classNames({'changed': changed['statisticsFileRefresh'],
                                    'response_warning': !isNil(get(responseWarning, 'statisticsFileRefresh'))})}>{t('TEXT_REFRESH_P')}</td>
                                <td>
                                    <Checkbox id='popRefresh' name={t('TEXT_REFRESH_P')} propertyName='statisticsFileRefresh' onChange={onChangePopFileRefresh} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

const EditAuto = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {autoIpSet, autoComputerNameSet} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(isAllNil(autoIpSet, autoComputerNameSet)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('DID_LFD_SEL_AUTO')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(autoIpSet) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['autoIpSet'],
                                    'response_warning': !isNil(get(responseWarning, 'autoIpSet'))
                                    })}>{t('MIS_TEXT_AUTO_IP_SET_P')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='autoIpSet' value={autoIpSet} selects={deviceOptions.tfOptions} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(autoComputerNameSet) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['autoComputerNameSet'],
                                    'response_warning': !isNil(get(responseWarning, 'autoComputerNameSet'))
                                    })}>{t('MIS_TEXT_AUTO_COMPUTERNAME_SET_P')}</th>
                                <td colSpan="2">
                                    <RadioGroup propertyName='autoComputerNameSet' value={autoComputerNameSet} selects={deviceOptions.tfOptions} onChange={onChange} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditLinkData = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {filedataDelSize, contentReadyInterval, playerStartTimeout} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(isAllNil(filedataDelSize, contentReadyInterval, playerStartTimeout)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>Link Data</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(filedataDelSize) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['filedataDelSize'],
                                    'response_warning': !isNil(get(responseWarning, 'filedataDelSize'))
                                    })}>{t('MIS_TEXT_MAX_STORAGE_SIZE_P')}</th>
                                <td>
                                    <NumberInput propertyName='filedataDelSize' width={70} value={filedataDelSize} onChange={onChange} forceInt />
                                    <span>MB</span>
                                </td>
                            </tr>
                        }
                        {
                            !isNil(contentReadyInterval) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['contentReadyInterval'],
                                    'response_warning': !isNil(get(responseWarning, 'contentReadyInterval'))
                                    })}>{t('MIS_TEXT_PLAY_LOADING_INTERVAL_P')}</th>
                                <td>
                                    <NumberInput propertyName='contentReadyInterval' width={70} value={contentReadyInterval} onChange={onChange} forceInt />
                                    <span>{t('COM_SID_MIN')}</span>
                                </td>
                            </tr>
                        }
                        {
                            !isNil(playerStartTimeout) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['playerStartTimeout'],
                                    'response_warning': !isNil(get(responseWarning, 'playerStartTimeout'))
                                    })}>{t('MIS_TEXT_PLAY_WAITING_TIME_P')}</th>
                                <td>
                                    <NumberInput width={70} propertyName='playerStartTimeout' value={playerStartTimeout} onChange={onChange} forceInt />
                                    <span>{t('COM_SID_SEC')}</span>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditContentDownloadStatus = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {contentsProgressEnable, contentsProgressInterval, contentsProgressUnit, contentsDownloadMode, isBackupPlayer} = {}, 
        changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(isAllNil(contentsProgressEnable, contentsProgressInterval, contentsProgressUnit, contentsDownloadMode)) {
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('TEXT_TITLE_CONTENT_DOWNLOAD_STATUS_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            !isNil(contentsProgressEnable) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['contentsProgressEnable'],
                                    'response_warning': !isNil(get(responseWarning, 'contentsProgressEnable'))
                                    })}>{t('COM_TEXT_SETTING_P')}</th>
                                <td>
                                    <RadioGroup propertyName='contentsProgressEnable' selects={deviceOptions.tfOptions} value={contentsProgressEnable} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(contentsProgressInterval) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['contentsProgressInterval'],
                                    'response_warning': !isNil(get(responseWarning, 'contentsProgressInterval'))
                                    })}>{t('TEXT_INTERVAL_P')}</th>
                                <td>
                                    <NumberInput width={70} propertyName='contentsProgressInterval' value={contentsProgressInterval} onChange={onChange} min={1} max={100} forceInt />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(contentsProgressUnit) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['contentsProgressUnit'],
                                    'response_warning': !isNil(get(responseWarning, 'contentsProgressUnit'))
                                    })}>{t('TEXT_JOB_UNIT_P')}</th>
                                <td>
                                    <RadioGroup propertyName='contentsProgressUnit' selects={deviceOptions.progressUnit} value={contentsProgressUnit} onChange={onChange} />
                                </td>
                            </tr>
                        }
                        {
                            !isNil(contentsDownloadMode) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['contentsDownloadMode'],
                                    'response_warning': !isNil(get(responseWarning, 'contentsDownloadMode'))
                                    })}>{t('MIS_SID_DOWNLOAD_MODE')}</th>
                                <td>
                                    {
                                        isBackupPlayer === true ?
                                        t('MIS_SID_DEVICE_ASSINGED_BACKUP_CONTENT') :
                                        <RadioGroup propertyName='contentsDownloadMode' selects={deviceOptions.downloadMode} value={contentsDownloadMode} onChange={onChange} />
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditEdgeServer = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {edgeServers, onlyEdgeServer, preconfig} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(isAllNil(edgeServers, onlyEdgeServer)) {
        return null;
    }

   /* let setContentDownload = false;
    if(!isNil(preconfig)) {
        preconfig.device_service_confs.map((item) => {
                if (item.service_type === 'contents_download') {
                    setContentDownload = true;
                }
            }
        );
    }

    if(setContentDownload) {
        return null;
    }*/

    if(isAllNil(edgeServers, onlyEdgeServer)) {
        return null;
    }

    const onChangeEdgeServer = e => {
        if(edgeServers && edgeServers.filter(ds => ds.selected).length > 10) {
            toastr.error(t('MIS_MESSAGE_DEVICE_MAX_CHECK_DN_SERVER_P'));
            return;
        }
        onChange(e, e.target.checked);
    };

    const onChangeOnlyEdgeServer = (e, value) => {
        if(value) {
            if(edgeServers.some(ds => ds.selected)) {
                onChange(e, value);
                edgeServers.forEach((ds, i) => {
                    if(ds.selected) {
                        onChange({'data-name': `edgeServers[${i}].selected`}, ds.selected);
                    }
                });
            } else {
                toastr.error(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
            }
        } else {
            onChange(e, value);
            edgeServers.forEach((ds, i) => {
                if(ds.selected) {
                    onChange({'data-name': `edgeServers[${i}].selected`}, ds.selected);
                }
            });
        }
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('MIS_SID_EDGE_SERVER')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        {
                            edgeServers &&
                            <tr>
                                <th>{t('MIS_SID_EDGE_SERVER')}</th>
                                <td>
                                    <table style={{width: '450px'}}>
                                        <colgroup>
                                            <col width="30px"/>
                                            <col width="210px"/>
                                            <col width="210px"/>
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t('TABLE_SERVER_NAME_P')}</th>
                                                <th>{t('TABLE_IP_ADDR_P')}</th>
                                                <th>{t('MIS_SID_CONNECTED_DEVICES')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                edgeServers.map((e, i) => {
                                                    return (
                                                        <tr key={`${e.hostName}_${i}`} style={{display: 'table-row'}}>
                                                            <td>
                                                                <Checkbox id={`${e.hostName}_${i}`} checked={e.selected} propertyName={`edgeServers[${i}].selected`} onChange={onChangeEdgeServer} />
                                                            </td>
                                                            <td className={classNames({
                                                                'changed': get(changed, `edgeServers[${i}].selected`),
                                                                'response_warning': !isNil(get(responseWarning, `edgeServers[${i}]`))
                                                                })}>{e.hostName}</td>
                                                            <td className={classNames({'changed': get(changed, `edgeServers[${i}].selected`)})}>{e.ipAddress}</td>
                                                            <td className={classNames({'changed': get(changed, `edgeServers[${i}].selected`)})}>{e.connectedDevices}</td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                        {
                            !isNil(onlyEdgeServer) &&
                            <tr>
                                <th className={classNames({
                                    'changed': changed['onlyEdgeServer'],
                                    'response_warning': !isNil(get(responseWarning, 'onlyEdgeServer'))
                                    })}>{`${t('MIS_SID_EDGE_SERVER')} Only`}</th>
                                <td>
                                    <RadioGroup propertyName='onlyEdgeServer' selects={deviceOptions.onlyServer} value={onlyEdgeServer} onChange={onChangeOnlyEdgeServer} />
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const ContentDownloadURLOfPreset = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {preconfig} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(isAllNil(preconfig)) {
        return null;
    }

    if(preconfig.device_service_confs.filter(s => s.service_type === 'contents_download').length <= 0){
        return null;
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('MIS_TABLE_DOWNLOAD_SERVER_P')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    {
                        !isNil(preconfig.device_service_confs) &&
                        preconfig.device_service_confs.map((item) => {
                                if(item.service_type === 'contents_download'){
                                    return (
                                        <tr>
                                            <th>URL</th>
                                            <td colSpan="2">
                                                {item.protocol.toLowerCase() + '://' + item.host + ':' + item.port + item.path}
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                        )
                    }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditUrlLauncher = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {urlLauncherAddress, urlLauncherTimeout} = {}, changed, responseWarning = {}} = setupInfo || defaultSetupInfo;

    if(isAllNil(urlLauncherAddress, urlLauncherTimeout)) {
        return null;
    }

    const onChangeUrlLauncher = (e) => {
        onChange && onChange(e, e.target.getAttribute('data-value'));
        const eventDataName = e.target.getAttribute('data-name');
        if(eventDataName === 'urlLauncherTimeout'){
            onChange && onChange({'data-name':'urlLauncherAddress'}, urlLauncherAddress);
        } else {
            onChange && onChange({'data-name':'urlLauncherTimeout'}, urlLauncherTimeout);
        }
    }

    return (
        <div className="devicepop setup mb26">
            <h3>{t('COM_TV_SID_URL_LAUNCHER')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={classNames({
                                'changed': changed['urlLauncherAddress'],
                                'response_warning': !isNil(get(responseWarning, 'urlLauncherAddress'))
                                })}>{t('COM_LIST_TITLE_URL')}</th>
                            <td>
                                <TextInput width={250} propertyName='urlLauncherAddress' value={urlLauncherAddress} onChange={onChangeUrlLauncher} />
                            </td>
                        </tr>
                        <tr>
                            <th className={classNames({
                                'changed': changed['urlLauncherTimeout'],
                                'response_warning': !isNil(get(responseWarning, 'urlLauncherTimeout'))
                                })}>{t('COM_MRMS_SCRTE_SHOW_DIALOG_TIMEOUT_NOTES')}</th>
                            <td>
                                <Select width={100} selects={deviceOptions.urlLauncherTimeout} propertyName='urlLauncherTimeout' value={urlLauncherTimeout} onChange={onChangeUrlLauncher} multiLang={false} direction={'up'} />
                                <span style={{display: 'inline-block', lineHeight: '30px', marginLeft: '8px', verticalAlign: 'top'}}>
                                    {t('TEXT_SEC_P')}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export const EditPinCode = ({setupInfo, onChange}) => {
    const {t} = useTranslation();
    const {data: {pinCode} = {}, changed, errors, responseWarning = {}} = setupInfo || defaultSetupInfo;
    const [showPinCode, setShowPinCode] = useState(false);
    const [currentPinCode, setCurrentPinCode] = useState(pinCode);

    useEffect(() => {
        setCurrentPinCode(pinCode);
    }, [pinCode]);

    if(isNil(pinCode)) {
        return null;
    } 

    const generateDefaultPin = (len) => {
        let zeroPin = '0'
        let pin = '' 
        for(let i=0 ; i < len; i++) {
            pin += zeroPin;
        }
        return pin;
    } 
    const onBlur = e => {
        if( e.target.value.length !== pinCode.length){
            e.target.value = pinCode;
            setCurrentPinCode(pinCode);
        }
        onChange && onChange(e);
    }

    const onChangePincode = e => {
        let modifiedPinCode = e.target.value;

        if(isNaN(modifiedPinCode)) {
            e.target.value = generateDefaultPin(pinCode.length);
            modifiedPinCode = e.target.value;
        }

        setCurrentPinCode(modifiedPinCode);
        
        if(modifiedPinCode && modifiedPinCode.length === pinCode.length){
            delete errors["pinCode"];
        }
        else
        {
            errors["pinCode"] = "Pincode length is not proper";
        }   
    }

    const onMouseDownPinCodeView = () => {
        setShowPinCode(true);
    };

    const onMouseUpPinCodeView = () => {
        setShowPinCode(false);
    };
    const areSpecialChars=(char)=>{
        let specialCharArray=['!','@','#','$','%','^','&','*','(',')'];
        if(specialCharArray.indexOf(char)!==-1)
        {
            return true;
        }
        return false;
    }

    const onKeyDownPassword = e => {                    
        switch(e.keyCode){
            case 48: case 49: case 50: case 51: case 52: case 53: case 54: case 55: case 56: case 57:
                if(areSpecialChars(e.key))
                {
                    e.preventDefault();
                }
            break;
            case 96: case 97: case 98: case 99: case 100: case 101: case 102: case 103: case 104: case 105: /*NUM 0~9*/
            case 8: /*BS*/ case 9: /*TAB*/ case 37: /*LARROW*/ case 39: /*RARROW*/ case 46: /*DEL*/
                break;
            default:                
                e.preventDefault();
        }
    };

    return (
        <div className="devicepop setup mb26">
            <h3>{t('MIS_SID_SERVER_PIN_CODE')}</h3>
            <div className="detail_view mini_p mt14 mb15">
                <table>
                    <colgroup>
                        <col width="203px"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className={classNames({
                                'changed': changed['pinCode'] && currentPinCode.length === pinCode.length,
                                'response_warning': !isNil(get(responseWarning, 'pinCode'))
                                })}>{t('MIS_SID_SERVER_PIN_CODE')}</th>
                            <td>
                                <TextInput type={showPinCode ? 'text' : 'password'} width={120} propertyName='pinCode' onBlur={onBlur} value={currentPinCode} onChange={onChangePincode} onKeyDown={onKeyDownPassword} maxLength={pinCode.length}>
                                    <WhiteButton id='pincodeView' name={t('BUTTON_VIEW_P')} style={{marginLeft: 8}} onMouseDown={onMouseDownPinCodeView} onMouseUp={onMouseUpPinCodeView} />
                                </TextInput>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EditSetup = (props) => {
    const {setupInfo: {data: {deviceType} = {}} = {}} = props;

    return (
        <div className='device_edit_panel_wrap mt28'>
            <EditTimeZone {...props} />
            <EditNetwork {...props} />
            <EditSmartFeature {...props} />
            <EditPeriod {...props} />
            {
                deviceType !== LPLAYER &&
                <EditEtc {...props} />
            }
            <EditVNC {...props} />
            <EditManage {...props} />
            <EditAuto {...props} />
            <EditLinkData {...props} />
            <EditContentDownloadStatus {...props} />
            {
                deviceType !== LPLAYER &&
                <EditEdgeServer {...props} />
            }
            {/* <ContentDownloadURLOfPreset {...props} /> */}
            <EditUrlLauncher {...props} />
            <EditPinCode {...props} />
        </div>
    );
};

export default EditSetup;
