import {isArray, isNil, isEmpty, isObject,transform, isEqual, trim} from 'lodash';
import React, {useRef, useState} from "react";
import { playlist } from 'reducers/playlist.reducer';
import i18n from '../language/i18n';
import {useOutsideClick} from "./hooks";
import {PLAYLIST_TYPES} from "../constants"

const userIdMinLength = 3;
const userIdMaxLength = 20;

export function isAllNil(...args) {
    return !args.some(arg => !isNil(arg));
}

export function isAllNilEmpty(...args) {
    return !args.some(arg => (!isNil(arg) || !isEmpty(arg)));
}

export function dateToString(date) {
    if(!date) {
        return;
    }
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

export function toFahrenheit(celsius) {
    return celsius * 1.8 + 32;
}

export function findWithDefault(arr, callback, def = {}) {
    return arr ? (arr.find(callback) || def) : def;
}

export function messageLine (str) {
    if( typeof str !== 'string' ) return "";
    const s = str
        .replace(/<br>/gi,'\n')
        .split('\n')
        .map( (item,i) =>{ return  <p style={{margin:0}} key={i}>{item}</p>; });
    return s;
}

export function getTabWidth (tabs, oriWidth, submenuWidth) {
    let exceptArea = submenuWidth || 254;
    if (tabs !== undefined && tabs.length > 1) {
        const maxWidth = window.innerWidth - exceptArea - 400;
        const tabNumber =  tabs.length;
        let resizeWidth, width = (oriWidth + 35);
        if ((tabNumber * width) > maxWidth) {
            resizeWidth = Math.ceil(((tabNumber * width) - maxWidth) / tabNumber);
            width = ((width - resizeWidth) - 40);
        } else {
            resizeWidth = Math.ceil((maxWidth - (tabNumber * width)) / tabNumber);
            width = width + resizeWidth - 40;
        }

        if (width > 169) {
            width = 169;
        } else if (width < 20) {
            width = 20;
        }
        return width;
    }
    return 169;

}

const ENGLISH = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_";
export function stringIsEnglish(str) {
    for (let index = str.length - 1; index >= 0; --index) {
        if (ENGLISH.indexOf(str.substring(index, index + 1)) < 0) {
            return false;
        }
    }
    return true;
}

const ipFilter = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
// const ipFilter = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;

export function isRightIpv4Andv6(address) {
	return ipFilter.test(address);
}

export function checkPhoneNum(number){
    const filter=/^(\+[0-9]|[0-9]){1}([0-9])*((-[0-9]+)*)$/i;
    return filter.test(number);
}

export function checkemail(email){
    ///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const filter=/^[\w-']+(\.[\w-']+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
    //var filter=/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    return filter.test(email);
}

export function convertJsonToObjectForTemplateFrame (json) {
    const frameList = [];
    if (json !== undefined && json.length > 0) {
        for (const frameFromJson of json) {
            const frames = {};
            frames.frames = [];
            const resolution = frameFromJson.resolution.width +'*' + frameFromJson.resolution.height;
            if (frameFromJson.frameInfos !== undefined) {
                for (const frameInfo of frameFromJson.frameInfos) {
                    const frame = {};
                    frame.id = frameInfo.index;
                    frame.frameName = frameInfo.frameName;
                    //frame.templateType = frameInfo.frameType.toLowerCase();
                    frame.frameType = frameInfo.frameType;
                    frame.x = frameInfo.x;
                    frame.y = frameInfo.y;
                    frame.width = frameInfo.width;
                    frame.height = frameInfo.height;
                    frame.resolution = resolution;

                    frame.templateId = frameFromJson.templateId !== undefined ? frameFromJson.templateId : undefined;

                    frames.frames.push(frame);
                }
                frameList.push(frames);
            }
        }
    }
    return frameList;
}


export function getNextYearDate() {
    const nextYear = new Date();
    const current = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    return nextYear;
}

export function cloneWithoutNil(obj) {
    let res = {...obj};
    Object.keys(res).forEach(key => isNil(res[key]) && delete res[key]);
    return res;
};

export const checkNumberValidation = (value, min, max) =>{
    let filter = /[^0-9]/gi;

    if (filter.test(value) == true || isEmpty(value) || isNil(value)) {
        value = min;
    }

    if(value != null && value != ''){
        value = parseInt(value);

        if(min !=null && value < min){
            value = min;
        }
        if(max != null && value > max){
            value = max;
        }
    }

    return value;
}


export const time24hhTo12hh = ( hh24, mm ) => {
    let HH = Number(hh24);
    const MM = Number(mm);
    let ampm = 1 ; // 1:am 0:pm
    if(HH < 1){
        ampm = 1;
        HH = 12;
    } else if ( HH < 12 ) {
        ampm = 1;
    } else if ( HH < 13 ) {
        ampm = 0;
        HH = 12 ;
    } else if ( HH < 24 ) {
        ampm = 0;
        HH = HH - 12;
    }
    return {hh: HH, mm: MM, ampm: ampm}
}

export const time12hhTo24hh = ( hh12, mm, ampm ) =>{
    let HH = Number(hh12);
    const MM = Number(mm);
    if ( ampm === 1 || ampm === '1'){
        if(HH === 12 ){
            HH = 0;
        }
    } else {
        if( HH === 12){
            HH = 12
        } else {
            HH = HH + 12;
        }
    }
    return {hh:HH, mm:MM}
}

export function toMomentDateTimeFormat(dateFormat, timeFormat) {
    return `${dateFormat.toUpperCase()} ${timeFormat === 'ampm' ? 'hh:mm A' : 'HH:mm'}`;
}

export function toMomentTimeFormat(timeFormat) {
    return timeFormat === 'ampm' ? 'hh:mm A' : 'HH:mm';
}

export function is12HourFormat(timeFormat) {
    return (!isNil(timeFormat) && timeFormat === 'ampm');
}

export function isDDMMFormat(dateFormat) {
    return (!isNil(dateFormat) && dateFormat.indexOf('dd') === 0);
}

export function isMMDDFormat(dateFormat) {
    return (!isNil(dateFormat) && dateFormat.indexOf('dd') === 3);
}

export function pickByName(source, name) {
    let result = isArray(source) ? [] : {};
    for(const prop in source) {
        if(prop === name) {
            result[prop] = source[prop];
        } else if(isObject(source[prop])) {
            result[prop] = pickByName(source[prop], name);
        }
    }
    return result;
}

export function getLocale() {
    const localeFromLocalStorage = localStorage.getItem('MagicInfoPremiumLanguage');
    return localeFromLocalStorage || 'en';
}

export const setLocale = (lang) => {
    localStorage.setItem('MagicInfoPremiumLanguage', (lang||"en"));
    i18n.changeLanguage(lang, (err, t) => {
        if (err) return console.log('something went wrong loading', err);
    })
}

export function differenceObject(object, base) {
    function changes(object, base) {
        return transform(object, function(result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

export function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    if (query) {
        const keys = query.split("&");
        if (keys) {
            for (const key of keys) {
                const pair = key.split("=");
                if (pair != null && pair.length > 1 && pair[0] === variable) {
                    return pair[1];
                }
            }
        }
    }
    return undefined;
}

export const getLicenseSummary = (licenses, orgLicenses) => {
    let temp = [];
    let licTypes = [];

    licenses.forEach((lic) => {
        if(['01064A', '01015A'].indexOf(lic.productCode) < 0){
            return;
        }
        if(!lic.licenseType === 'Charged'){
            return;
        }

        if(!temp.includes(lic.productCode)){
            temp.push(lic.productCode);
            const productName = lic.productName.indexOf('Unified Player') >= 0 ? 'Unified Player' : lic.productName;
            licTypes.push({productCode:lic.productCode, productName:productName, totalCount:lic.maxClients});
        } else {
            licTypes = licTypes.map(type => {
                if(lic.productCode === type.productCode)
                    return {...type, totalCount:type.count + lic.maxClients};
                else
                    return type;
            });
        }
    })

    licTypes.forEach((licType, i) => {
        let assignedLicense = 0;
        let assignedDevice = 0, unassignedDevice = 0;

        orgLicenses.forEach(item => {
            const licenseInfo = item.orgLicenseEntities.filter(f => f.productCode === licType.productCode)[0];
            if(licenseInfo){
                if(licenseInfo.assigned){
                    assignedLicense += licenseInfo.maxLicenseCount;
                    assignedDevice += licenseInfo.usedLicenseCount;
                } else {
                    unassignedDevice += licenseInfo.usedLicenseCount
                }
            }
        });
        licTypes[i] = {...licType, assignedLicense, assignedDevice, unassignedDevice, available: licType.totalCount - assignedLicense - unassignedDevice};
    });

    return licTypes.sort((a, b) => {
        return ['01015A', '01064A', '010311'].indexOf(a.productCode) - ['01015A', '01064A', '010311'].indexOf(b.productCode)
    });
}

export const createMultilineHtmlStringUsingSeperator=(pInputString,pSeperator,pEmptyStringSwap)=>{
    if(isEmpty(pInputString))
    {
        return pEmptyStringSwap;
    }
    let seperatedArray=pInputString.split(pSeperator);
    let arrayLength=seperatedArray.length;
    if(arrayLength<=1)
    {
        return pInputString;
    }

    const finalData=seperatedArray.map((item,i)=>{
        item=trim(item);
        return isEmpty(item)?'':<div key={i}>{item}</div>
       });
    return finalData;
} 

// data format shoule be [{key:"",title:"",value:""}]
export const DataListWithMoreButton = ({data, width,countToShowMore=3}) => {
    const [show, setShow] = useState(false);
    const ref = useRef();
    useOutsideClick(ref,()=> {
        setShow(false);        
    })  
    let length= data!==undefined ? data.length:0;
    const showMoreData = (e) => {
        e.stopPropagation();
        setShow(!show);
    }   
    const DataLi=({data})=>{
        return (
            data!== undefined ? <li key={data.key} title={data.title}>{data.value}</li>:''
        )
    } 
    const renderInitialElements=()=>{
        let finalData=[];
        for(var i=0;i<countToShowMore;i++)
        {
            finalData.push(<DataLi data={data[i]}/>);
        }
        return finalData;
    }
    return (
        <ul ref={ref}>
            {renderInitialElements()}
            {length>countToShowMore && <li>
                <button className="list_more_wrap" onClick={showMoreData}></button>
                <div className="list_more" style={{display: !show ? 'none' : '', width: width}} onClick={showMoreData}>
                    <div style={{overflowY:'scroll', height:177}}><span></span>
                        <ul style={{overflow:'hidden',height:'auto'}}>
                            {
                                data.map(item => <DataLi data={item}/>)
                            }
                        </ul>
                    </div>
                </div>
            </li>
}
        </ul>
    )
}

export const getElementById=(id) =>{
    if(!isNil(id)) {
       return document.getElementById(id);
    }
    return null;
}

//[V9 RC #28]
export const getUnRecognizedData = (userid) => {
    if(userid.length === userIdMinLength) {  // minimum length of User ID changed from 5 to 3
        return userid.substring(0,1) + '*'.repeat(userid.length-2) + userid.substring(userid.length-1,userid.length);
    }
    return userid.substring(0,2)+'*'.repeat(userid.length-3)+userid.substring(userid.length-1,userid.length);
}

export const parseStringToPositiveNumber=(numberString,falseSafeValue)=>
{
    numberString=numberString.trim();
    var value=parseInt(numberString,10);
    if(isNaN(value) || value < 0)
    {
        return falseSafeValue;
    } 
    return value;
};

export const getMainThumbnailContentDetails = (contents, mainThumbnailId, playlistType, contentIndex) => {
    let mainThumbnailContentDetail;
    if(playlistType === PLAYLIST_TYPES.TAG) {
        mainThumbnailContentDetail = contents.get(mainThumbnailId);
    } else {
        mainThumbnailContentDetail = contents[contentIndex];
    }
    return mainThumbnailContentDetail;
}

function onlyContent(content) {
    return !content.isSubPlaylist;
}

export const getAllContentsIds = (playlistContents) => {
    let contentIds = [];
    const contents = playlistContents.filter(onlyContent);
    contents.forEach(content => contentIds.push(content.contentId));
    return contentIds;
}

export const allSubplaylist = (playlist) => {
    if(playlist.playlistType === PLAYLIST_TYPES.TAG) {
        return false;
    }
    const playlistContents = playlist.contents;
    const contents = playlistContents.filter(onlyContent);
    return isEmpty(contents) ? true : false;
}

export const getTagsContentMap = (playlist) => {
    let hashMap = new Map();
    const tagsArray = playlist.tags;
    const tagsArrayLength = tagsArray.length;
    for(let i = 0; i < tagsArrayLength; i++) {
        const tagContentArray = tagsArray[i].contents;
        if(isNil(tagContentArray)) {
            continue;
        }
        const tagContentArrayLength = tagContentArray.length;
        for(let j = 0; j < tagContentArrayLength; j++){
            if(!hashMap.has(tagContentArray[j].thumbFileId)) {
                hashMap.set(tagContentArray[j].thumbFileId, tagContentArray[j]);
            }
        }
    }
    return hashMap;
}
export const scaleImage = (resolutions) => {
    
    let width = parseInt(resolutions[0]);
    let height = parseInt(resolutions[1]);
    let clientWidth = document.body.clientWidth;
    let clientHeight = document.body.clientHeight; 

	//Get both ratios to decide scaling,
	//if width & height both are greater then client width & height then scale with max ratio.
    let wRatio = width / clientWidth;
    let hRatio = height / clientHeight;
	
	//If it needs vertical scaling
	if((width > clientWidth) || (height > clientHeight))
	{
		if(hRatio > wRatio)
		{
			height = clientHeight;
			width = width *1 / hRatio;
			return [width, height];
		}
		else //wRatio > hRatio, it needs horizontal scaling
		{
			width = clientWidth;
			height = height * 1 / wRatio;
			return [width, height];
		}
	}
    else {
        return [width, height];
    }
}

export const userIdLengthRangeCheck = (id) => {
    return (id.length >= userIdMinLength && id.length <= userIdMaxLength);
}

export const closeDetailPopup = (deletePopupId, closePopup) => {
    let deletePopupIdLength = deletePopupId.length;
    for(let i = 0; i<deletePopupIdLength; i++){
        closePopup(deletePopupId[i]);
    }
}
