import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import isEmpty from 'lodash/isEmpty';
import Checkbox from "../../components/checkbox/Checkbox";
import TagNameCell from "../../components/popup/tag/TagNameCell";
import {settingService} from "../../services";
import {useTranslation} from "react-i18next";
import {convertTagContent, useSideClick} from "./playlistUtil";
import {bytesToSize, usePrevious} from "../../helper";
import {playlistConstants, TAG_ITEM} from "../../constants/PlaylistConstants";
import {contentConstants} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import flatMap from "lodash/flatMap";
import isNil from "lodash/isNil";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {cloneDeep} from "lodash";
import TagValueSelectCell from "../../components/popup/tag/TagValueSelectCell";
import {Loading} from "../../components/loading/Loading";
import {getErrorMessage} from "helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';

const TagListView = React.forwardRef(({filter, tags, addPlaylistItem, updatePlaylistItem, deletePlaylistItem, addPopup, closePopup, style, mode}, ref) => {
    const {t} = useTranslation();
    const [listHeight , setListHeight] = useState(window.innerHeight - 262);
    const [tagFilter, setTagFilter] = useState({
        organId: filter.organId,
        searchText: filter.searchText,
        startIndex: 1,
        pageSize: 15,
        refresh: true,
    });

    const [loading, setLoading] = useState(true);

    const prevOrganId = usePrevious(filter.organId);
    const prevTags = usePrevious(tags);

    useEffect(()=> {
        if (prevOrganId !== filter.organId) {
            tagFilter.startIndex = 1;
        }
        setTagFilter({...tagFilter, organId: filter.organId, searchText: filter.searchText, refresh: true});
    }, [filter])

    useEffect(()=> {
        prevTags && prevTags.length === 0 && mode === 'EDIT' && tags.length > 0 && setTagFilter({...tagFilter, refresh: true});
    }, [tags])

    useEffect(()=> {
        if (data.tagList) {
            const tagList = makeTagListViewData(data.tagList);
            setData({...data, tagList})
        }
    }, [tags])

    useEffect(() => {
        setListHeight(window.innerHeight - 262);
    });

    const makeSelectedTags = () => {
        return isEmpty(tags) ? [] : tags.map((tag) =>{
            return {
                tagId: tag.tagId,
                tagName: tag.name,
                tagConditionTitle: tag.tagConditionTitle ? tag.tagConditionTitle :  tag.tagConditions && tag.tagConditions.map((condition, index)=>{
                    if (!isEmpty(condition)) {
                        if (condition.tagConditionId === -1) {
                            return 'Not Assigned';
                        } else {
                            return condition.tagCondition;
                        }
                    }
                }).toString(),
                duration: tag.duration,
                tagConditions: tag.tagConditions && tag.tagConditions.map((condition)=> {
                    if (!isEmpty(condition)) {
                        return {
                            tagCondition: condition.tagCondition,
                            tagConditionId: condition.tagConditionId,
                            conditionValue: condition.tagCondition,
                            conditionId: condition.tagConditionId,
                        }
                    }
                }).filter(item => !isEmpty(item))
            }
        })
    }

    const [checkBox, setCheckBox] = useState({
        checkBox: [],
    });

    const [data, setData] = useState({
        tagList: [],
        selectedTagList: makeSelectedTags(),
        changedTag: undefined,
        totalCount: 0,
    });

    const preSelectedTagList = usePrevious(data.selectedTagList);

    useEffect(() => {
        const selectedTagList = makeSelectedTags();
        const selTagIds = flatMap(selectedTagList, (filteredTag) => {
            return [filteredTag.tagId]
        });
        const prevSelTagIds = flatMap(preSelectedTagList, (filteredTag) => {
            return [filteredTag.tagId]
        });
        const deletedTag = prevSelTagIds ? prevSelTagIds.map((tagId) => {
            if (!selTagIds.includes(tagId)) {
                return tagId;
            }
        }).filter((item) => !isNil(item)): null;
        if (prevSelTagIds && deletedTag && prevSelTagIds.length > deletedTag.length && deletedTag.length > 0) {
            const findTag = data.tagList.find((tag) => tag.tagId === deletedTag[0]);
            if (findTag) {
                findTag.showCondition = false;
            }
        }
        setData({
            ...data,
            selectedTagList,
        })
    }, [data.tagList, tags])

    useEffect(()=> {
        setLoading(true);
        Promise.all([
            settingService.fetchTags(tagFilter),
        ]).then(values => {
            const fetchTagList = makeTagListViewData(values[0].items);
            if (tagFilter.refresh) {
                setData({...data, tagList: fetchTagList, totalCount : values[0].totalCount});
            } else {
                setData({...data, tagList: data.tagList.concat(fetchTagList), totalCount : values[0].totalCount});
            }
            setLoading(false);
        }).catch(e => {
            if(e.errorCode === 408900) {
                return;
            }
            toastr.error(getErrorMessage(e));
            setLoading(false);
        });
    }, [tagFilter]);

    const setShowTagInfo = (original) => {
        const selectedTag = {...original};
        const selTag = data.selectedTagList.find(tag => tag.tagId.toString() == original.tagId.toString());
        selectedTag.tagConditions = selTag ? selTag.tagConditions: [];
        const tagList = cloneDeep(data.tagList);
        tagList.map(tag => {tag.showCondition = false;});
        
        addPopup({
            id: playlistConstants.LOAD_TAG_INFORMATION,
            type: playlistConstants.LOAD_TAG_INFORMATION,
            original: selectedTag,
            tagList,
            selectedTagList: [selectedTag],
            onSave: (item) => {saveTagItem(item)},
            onClose: () => closePopup(playlistConstants.LOAD_TAG_INFORMATION),
        });
    }

    const saveTagItem = (item) => {
        const {selectedTagList, tagList} = data;

        if (selectedTagList.findIndex((selTag)=> selTag.tagId === item.tagId) < 0) {
            checkBox.checkBox[item.tagId].checked = true;
            setCheckBox({checkBox: checkBox});
            selectedTagList.push(item);
            setData({ ...data, selectedTagList});
            addPlaylistItem(item, null, TAG_ITEM);
        } else {
            const updatedItemIndex = selectedTagList.findIndex((selTag)=> selTag.tagId === item.tagId);
            selectedTagList[updatedItemIndex].tagConditionTitle = item.tagConditionTitle;
            selectedTagList[updatedItemIndex].tagConditions = item.tagConditions;
            const itemIndex = tagList.findIndex((selTag)=> selTag.tagId === item.tagId);
            if(item.tagConditionTitle != 'True' && item.tagConditionTitle != 'False' && item.tagConditionTitle != ""){
                tagList[itemIndex].tagConditionTitle = item.tagConditionTitle;
                tagList[itemIndex].contentTagConditions = [];
            }
            else if(item.tagConditions.length > 0 && item.tagConditionTitle != tagList[itemIndex].tagConditionTitle){
                tagList[itemIndex].tagConditionTitle = item.tagConditionTitle;
                tagList[itemIndex].contentTagConditions = item.tagConditions;
            } 
            setData({ ...data, selectedTagList, tagList});
            updatePlaylistItem(item, null, TAG_ITEM);
        }
    }

    const onChangeTagListCondition = (changedTagList) => {
        setData({...data, tagList: changedTagList});
    };

    const getContentsWithTagValues = (tag, e) => {
        const condition = !isEmpty(tag.tagConditionList) ? tag.tagConditionList : [""];
        const checked = e.target.checked;

        checked === false ? deletePlaylistItem(tag,TAG_ITEM): settingService.fetchTagAndContentsWidthIdAndTagValues(
            tag.tagId, {ids: condition}
        ).then((res)=>{
            const tagItem = {...tag};
            tagItem.contents = res.items;
            addPlaylistItem(convertTagContent(tagItem), null, TAG_ITEM)
        })
    }

    const getDefaultTagCondition = (selectedTag) => {
        if (selectedTag.tagType === contentConstants.TAG_TYPE_BOOLEAN) {
            return ['-3'];
        } else {
            return [""];
        }
    };

    useEffect(()=> {
        data.changedTag && updateConditionWithTagInfo(data.changedTag)
    },[data.changedTag])

    const updateConditionWithTagInfo = (tag) => {
        let conditions=[];
        switch (tag.tagType) {
            case contentConstants.TAG_TYPE_NUMBER:
                conditions = !isEmpty(tag.contentTagConditions.filter((condition)=> condition.checkCondition)) ? tag.contentTagConditions.filter((condition)=> condition.checkCondition).map((condition)=> {return condition.tagCondition}) : getDefaultTagCondition(tag);
                break;
            case contentConstants.TAG_TYPE_BOOLEAN:
                conditions = tag.contentTagConditions.map((condition) =>{ return condition.tagConditionId});
                break;
            case contentConstants.TAG_TYPE_TEXT:
                conditions = !isEmpty(tag.contentTagConditions.filter((condition)=> condition.checkCondition)) ? tag.contentTagConditions.filter((condition)=> condition.checkCondition).map((condition)=> {return condition.tagConditionId}) : getDefaultTagCondition(tag);
                break;
        }

        settingService.fetchTagAndContentsWidthIdAndTagValues(
            tag.tagId, {ids: conditions}
        ).then((res)=>{
            const tagItem = {...tag};
            tagItem.contents = !isEmpty(res.items) ? [{thumbFileId: res.items[0].thumb_file_id, thumbFileName: res.items[0].content_name}] : [];
            updatePlaylistItem(tagItem, null, TAG_ITEM);
        })
    }

    const toggleRow = originalTag => {
        const {selectedTagList, tagList} = data;

        if (checkBox.checkBox.length > 0) {
            if (checkBox.checkBox[originalTag.tagId].checked) {
                if(selectedTagList.find(tagId => tagId === originalTag.tagId) === undefined) {
                    selectedTagList.push(originalTag);
                    setData({...data, selectedTagList: selectedTagList});
                }
            } else {
                selectedTagList.splice(selectedTagList.findIndex(tag => tag.tagId === originalTag.tagId), 1);

                tagList.map((tag) => {
                    if (tag.tagId === originalTag.tagId) {
                        tag.showCondition = false;
                    }
                });

                setData({...data, tagList, selectedTagList: selectedTagList});
            }
        }
    };

    const outSide = useRef();

    useSideClick(outSide, (e)=>{
        const {tagList} = data;
        if (e.target.className !== 'table' && e.target.className !== 'tag_condition_btn' && e.target.className !== 'tag_condition_input') {
            tagList.map((tag) => { tag.showCondition = false;});
            const tagList1 = cloneDeep(tagList);
            setData({...data, tagList:tagList1});            
        }
    })

    const tagColumns = useMemo(() => [
        {
            width: 40,
            accessor: 'checkbox',
            id: 'checkbox',
            resizable: false,
            Cell: ({original}) => {
                const isSelected = data.selectedTagList.find(tag => tag.tagId.toString() == original.tagId.toString());
                return (
                    <Checkbox id={'ContentDetailTag_'+original.tagId} classname="table" name="check" checked={isSelected}
                              ref={ref => checkBox.checkBox[original.tagId] = ref}
                              onClick={(e) => {
                                  getContentsWithTagValues(original, e);
                              }}
                              onChange={() => toggleRow(original)}/>
                );
            }
        },
        {
            Header: <span>{t("MIS_TEXT_TAG_NAME_P_KR_MIS20")}</span>,
            accessor: 'tagName',
            width: 100,
            resizable: false,
            Cell: ({original}) => <TagNameCell tag={original} onClick={() => setShowTagInfo(original)} />
        },
        {
            Header: <span>{t("MIS_SID_TAG_TYPE")}</span>,
            accessor: "tagType",
            width: 100,
            resizable: false,
            Cell: ({original}) => <div>{parseTagType(original.tagType)}</div>
        },
        {
            Header: <span>{t("MIS_TEXT_TAG_VALUE_P")}</span>,
            width: 360,
            resizable: false,
            Cell: ({original}) =>
                <TagValueSelectCell outSide={outSide} mode={"playlistGrid"} originalTag={original} tagListType={'media'} onChangeTagList={onChangeTagListCondition}
                                    tagList={data.tagList} selectedTagList={data.selectedTagList} onChangeCondition={onChangeCondition} useSelectAll={true}/>
        },
        {
            Header: <span>{t("COM_TEXT_SIZE_P")}</span>,
            width: 100,
            resizable: false,
            Cell: ({original})  => <span>{bytesToSize(original.tagSize)}</span>
        },
        {
            Header: <span>{t("TABLE_CONTENT_COUNT_P")}</span>,
            Cell: ({original})  => <span>{original.contentCount}</span>
        }
    ], [data.tagList, data.selectedTagList]);

    const makeTagListViewData = (tagList) => {
        return tagList.map(tag => {
            const selectedTagList = makeSelectedTags();
            const isSelected = selectedTagList && selectedTagList.find((selectedTag) => selectedTag.tagId == tag.tagId);
            let tagConditionTitle = selectedTagList && selectedTagList.find((selectedTag) => selectedTag.tagId === tag.tagId)
                && selectedTagList.find((selectedTag) => selectedTag.tagId === tag.tagId).tagConditionTitle;
            let defaultBooleanTagInfo = {
                tagId: tag.tagId,
                tagConditionId: -3,
                tagCondition: false,
            };

            if (tag.tagType === contentConstants.TAG_TYPE_BOOLEAN) {
                if (isSelected) {
                    let tagConditionId = -3, tagCondition = false;
                    if(!isNil(isSelected.tagConditions) && !isEmpty(isSelected.tagConditions)) {
                        tagCondition = isSelected.tagConditions[0].tagCondition === "true";
                        tagConditionId = isSelected.tagConditions[0].tagConditionId;
                    }
                    defaultBooleanTagInfo = {...defaultBooleanTagInfo,
                        tagConditionId: tagConditionId,
                        tagCondition: tagCondition
                    }
                    tagConditionTitle = defaultBooleanTagInfo.tagCondition ? t("MIS_TEXT_TRUE_P") : t("MIS_TEXT_FALSE_P");
                }
            } else {
                if (isSelected) {
                    tagConditionTitle = isSelected.tagConditionTitle;
                }
            }

            return {
                isSelected: isSelected ? true : false,
                tagId: tag.tagId,
                tagName: tag.tagName,
                tagValue: tag.tagValue,
                tagType: tag.tagType,
                tagConditionTitle: tagConditionTitle,
                tagSize: tag.totalSize,
                tagConditionList: [],
                showCondition: false,
                contentTagConditions: tag.tagType === contentConstants.TAG_TYPE_BOOLEAN ? [defaultBooleanTagInfo] : [],
                contentCount: tag.contentCount,
            }
        });
    }
    const parseTagType = (tagType) => {
        let parsedTagType = '';
        switch (tagType) {
            case 0:
                parsedTagType = t('DID_LFD_TEXT');
                break;
            case 1:
                parsedTagType = t('COM_ADMIN_CONTENT_TYPEINFO_CONTENTS_LIST_JSP_NUMBER');
                break;
            case 2:
                parsedTagType = t('MIS_TEXT_TRUE_OR_FALSE_P');
                break;

        }
        return parsedTagType;
    };

    const onChangeCondition = (changedTagList, selectTag) => {
        setData({...data, selectedTagList: changedTagList, changedTag: {...selectTag}});
    };

    return (
        <Fragment>
            <div style={{width: '100%', background: '#eff0f1', display: 'flex', height: listHeight}}>
                {
                    loading &&
                    <div className="list_wrap_loading" style={{height: listHeight}}>
                        <Loading isLoading={true}/>
                    </div>
                }
                {
                    !loading &&
                    <MagicInfoTable
                        data={data.tagList}
                        columns={tagColumns}
                        style={{width:'100%', height:'100%'}}
                        widthStyle={{width: '99%'}}
                        className="-striped -highlight"
                        showPagination={false}
                        manual
                        defaultPageSize={1}
                        noDataText={t("MESSAGE_COMMON_NO_DATA_P")}
                />
                }
                
            </div>
                {
                    data.totalCount > data.tagList.length &&
                        <div style={{display:'flex', marginTop: 10}}>
                            <WhiteButton width={'auto'} name={t("BUTTON_MORE_VIEW_P")} style={{margin: '0px auto'}}
                                onClick={()=> {
                                    const pageSize = 15;
                                    setTagFilter({...tagFilter, startIndex: tagFilter.pageSize + tagFilter.startIndex, pageSize: pageSize, refresh: false});
                            }} />
                        </div>
                }
        </Fragment>
    )
})


export default TagListView;